// import Scanner from './Scanner.js';
import { useMediaQuery, Divider,Spinner,Input, Image, Text,Drawer,Spacer,Link,Toggle} from '@geist-ui/core'
import './App.css';
import { ArrowLeft } from '@geist-ui/icons'
import Pwa from './Pwa';
import data from './data.json';
// import * as d3 from "d3";
import image_barcode from './css/barcode.png';
import React, { useEffect, useRef, useState } from 'react';
import Quagga from 'quagga';
import axios from 'axios';

const App = () => {

  // const firstUpdate = useRef(true);

  const [spinner, setSpinner] = React.useState(false)
  const [lang, setLang] = React.useState(false)

  const [state, setState] = React.useState(false)
  // const [error, setError] = React.useState(null);
  const [page, setPage] = React.useState(null);

  const [detale, setDetale] = React.useState(null);
  const [isStart, setIsStart] = useState(true);
  
  const [barcode, setBarcode] = useState(null);
  const [brands, setBrands] = useState(null);
  const [company, setCompany] = useState(null);
  const [status, setStatus] = useState(null);
  
  
  const isXS = useMediaQuery('xs')
  // const isSM = useMediaQuery('sm')

  useEffect(() => {
    // if (firstUpdate.current) {
    //   firstUpdate.current = false;
    //   return;
    // }

    if(isXS){
      if (isStart) startScanner()
      else stopScanner();
    }
    
    // setBarcode('3600542399241')
    // setBarcode('9000100477505')
    // setBarcode('3086120017040')
    // setBarcode('5449000054227')
    // setBarcode('3033490004521')
    // setBarcode('6925281939976')
    // setBarcode('8436024295276')
    // setBarcode('5900951295584')
    
    //  setBarcode('4305615166599')
    //  rossman
      // setBarcode('3600523909384') 
      // loreal
      //  setBarcode('4792252935396')
      //  tipson

      // setBarcode('400417025005')
      //ritter sport
      
      // setBarcode('4823065720166')
      // dolce
      // setBarcode('8436024298970')
      // iberica
      // setBarcode('7622210308108')
      //milka
      // setBarcode('4823063112680')
      //pepsi
      // setBarcode('7622210176219')
      //lays mondelez
      // setBarcode('4823063125253')
      //Лейс Пепсі
      // setBarcode('5053990101573')
      //kellogg
      // setBarcode('5711953158445')
      //Arla
      // setBarcode('4019300169115')
      //karwer
      //  setBarcode('8712561593335')
      //Dove Loreal
      
      
  }, [isStart,isXS]);
  
  
  // console.log(data,data.find(d=>d.famous_brands.match("Captain")))

  const _onDetected = res => {
    setBarcode(res.codeResult.code);
  };

  const stopScanner = () => {
    Quagga.offProcessed();
    Quagga.offDetected();
    Quagga.stop();
  };
  


React.useEffect(() => {  
  setIsStart(false)
  if (barcode && barcode.length>=12){
    if(barcode.slice(0,2)=='46'){
      setState(true)
      setStatus('This product is produced directly in Russia 😠')
      return
    }
    
    setBrands(null)
    setCompany(null)
    setPage(null)
    setStatus(null)
    setDetale(null)
    setState(true)
    // setError(null)
    setSpinner(true)
    let barcode_ = barcode[0]==0?barcode.slice(1,15):barcode
    console.log('barcode_',barcode_)
    axios.get(`https://api-barcodes-exit-ru.vercel.app/api/ean13_search?ean13=${barcode_}`)
    .then(response => {
      resp(response)
    })      
  }else{
    setIsStart(true) 
    // console.log('<13')
  }
}, [barcode]);



  
  const drawers = () => {
    setState(false)
    setIsStart(true)
    setBarcode(null)
  }
  
  
  
  const [value, setValue] = React.useState("")
  const handleSubmit = event => {
    event.preventDefault()
    setSpinner(true)
    setIsStart(false)
    setState(true)
    try {
      axios.get(`https://api-barcodes-exit-ru.vercel.app/api/text_search?text=${value}`)
      .then(response => {
      console.log(response)
        resp(response)
      })   
    
    } catch (error) {
      setCompany(value)
      // setSpinner(false)
      setBrands(null)
      // setCompany(null)
      // setPage(null)
      // setValue("")
      // setStatus("🤔")
      // setDetale(lang?"Жодної інформації, що ця компанія працює в Росії":"No information about the work of this company in Russia")
    }
  }
  
  
  
  
  function resp(response){
    if(response.data.data!='Nothing was found'){
      let resp = response.data.data[0]
      console.log('!',resp)
      setSpinner(false)
      setState(true)
      console.log('exitru',resp.name)
      setCompany(resp.name)
      setPage(lang?resp.companypage.uk:resp.companypage.en)
      let smile = resp.status.key=="stay"?"😠":resp.status.key=="wait"?"🙁":resp.status.key=="leave"?"🙂":resp.status.key=="exited"?"🥳":""
      setStatus(lang?resp.status.uk+" "+smile:resp.status.en +" " +smile)
      try {
        let detale_ =  lang?resp.reason.value.uk:resp.reason.value.en;
        setDetale(detale_.split(' ').length>40?detale_.split(' ').slice(0,40).join(' ')+'...': detale_)
      } catch (error) {
        setDetale(null)
      }
    }else{
      setCompany(null)
      setSpinner(false)
      setBrands(null)
      setPage(null)
      setValue("")
      setStatus("🙄")
      setDetale(lang?"Жодної інформації, що ця компанія працює в Росії":"No information about the work of this company in Russia")
    }
}
// console.log('App: ',state,isStart)

  
  
  
  
  const startScanner = () => {
    Quagga.init(
      {
        inputStream: {
          type: 'LiveStream',
          target: document.querySelector('#scanner-container'),
          constraints: {
            facingMode: 'environment' // or user
          }
        },
        numOfWorkers: navigator.hardwareConcurrency,
        locate: true,
        frequency: 1,
        debug: {
          drawBoundingBox: true,
          showFrequency: true,
          drawScanline: true,
          showPattern: true
        },
        multiple: false,
        locator: {
          halfSample: false,
          patchSize: 'large', // x-small, small, medium, large, x-large
          debug: {
            showCanvas: false,
            showPatches: false,
            showFoundPatches: false,
            showSkeleton: false,
            showLabels: false,
            showPatchLabels: false,
            showRemainingPatchLabels: false,
            boxFromPatches: {
              showTransformed: false,
              showTransformedBox: false,
              showBB: false
            }
          }
        },
        decoder: {
          readers: [
            // 'code_128_reader',
            'ean_reader',
            'ean_8_reader',
            // 'code_39_reader',
            // 'code_39_vin_reader',
            // 'codabar_reader',
            // 'upc_reader',
            // 'upc_e_reader',
            // 'i2of5_reader',
            // 'i2of5_reader',
            // '2of5_reader',
            // 'code_93_reader'
          ]
        }
      },
      err => {
        if (err) {
          return console.log(err);
        }
        Quagga.start();
      }
    );
    Quagga.onDetected(_onDetected);
    Quagga.onProcessed(result => {
      let drawingCtx = Quagga.canvas.ctx.overlay,
        drawingCanvas = Quagga.canvas.dom.overlay;
// console.log(drawingCanvas)
      if (result) {
        if (result.boxes) {
          drawingCtx.clearRect(
            0,
            0,
            parseInt(drawingCanvas.getAttribute('width')),
            parseInt(drawingCanvas.getAttribute('height'))
          );
          result.boxes.filter(box => box !== result.box).forEach(box => {
            Quagga.ImageDebug.drawPath(box, { x: 0, y: 1 }, drawingCtx, {
              color: 'green',
              lineWidth: 2
            });
          });
        }

        if (result.box) {
          Quagga.ImageDebug.drawPath(result.box, { x: 0, y: 1 }, drawingCtx, { color: '#00F', lineWidth: 2 });
        }

        if (result.codeResult && result.codeResult.code) {
          Quagga.ImageDebug.drawPath(result.line, { x: 'x', y: 'y' }, drawingCtx, { color: 'red', lineWidth: 3 });
        }
      }
    });
  };


  if (!isXS) return (
    <>
       <div style={{textAlign: "center",marginTop: "100px",padding: "20px",background:'blue',color:'#fff'}}>Sorry, only for mobile devices</div>
       <div style={{textAlign: "center",padding: "20px",background:'yellow'}}>Вибачте, тільки для мобільних пристроїв</div>
       <div style={{textAlign: "center",padding: "20px",color:'#fff'}}>
       ¯\_(ツ)_/¯
       </div>
    </>
  )
  
  
  
  

  return (
  <> 
    <div className="App">
      <header className="App-header">
        {state?<p style={{paddingTop:'20%'}}></p>:<div id="scanner-container"/>}
        
        <div style={{position:"absolute",top:'5%',left:'5%'}}><Link href="https://leave-russia.org/"><ArrowLeft color='#fff' size='32' /></Link></div>

        <div style={{position:"absolute",top:'6%',left:'40%'}}>
        <span style={{color:lang?'#777':'#fff'}}>en</span><Toggle onChange={event => setLang(event.target.checked)} style={{paddingLeft:'2px',paddingRight:"1px"}} type="secondary" /> <span style={{color:lang?'#fff':'#777'}}>ua</span>
        </div>
        
        <div style={{position:"absolute",top:'5%',right:'10%'}}>
          <Pwa />
        </div>
      </header>
      
      
      
      
      

      <div style={{color:'#fff',paddingTop:"5%",textAlign:'center',fontSize:'16px'}}>
            {state?lang?"Тапніть, щоб продовжити":"Tap to continue":lang?"Наведіть камеру на баркод":"Point the camera at the barcode"}
      </div>
      <div style={{color:'#aaa',padding:"10px",textAlign:'center'}}>
        {state?lang?"":"":lang?"або":"or"}
      </div>
      <div style={{textAlign:'center'}}>
        {state?"":<form onSubmit={handleSubmit}>
          <Input style={{color: "#fff"}} value={value} onChange={(e)=>setValue(e.target.value)} iconRight={spinner&&<Spinner />} placeholder={lang?"Введіть назву компанії чи бренду":"Type name or brand of company"} width="95%"/>
        </form>}
      </div>
      
      
      
      
      
      <Drawer visible={state} onClose={drawers} placement="bottom">
   
        <Image src={barcode&&image_barcode} />
        <Drawer.Subtitle>
        {barcode} {brands}
        </Drawer.Subtitle>
       
        <Spacer h={brands?6:0}/>
        <Drawer.Subtitle>
        {company&&(lang?"Компанія":"Company:")}
        </Drawer.Subtitle>
        <Drawer.Title>{company&&company}</Drawer.Title>
        {/* <p style={{color:'grey',paddingLeft:"20px"}}>
          <Image width="70%" src={"https:\/\/leave-russia.org\/gallery\/Logos\/sprite-03.svg?v=1689281672#mondelez"} />
        </p> */}
        <Drawer.Subtitle>
        {status&&(lang?"Статус":"Status:")}
        </Drawer.Subtitle>
        <Drawer.Title>{status?status:spinner?<Spinner />:''}</Drawer.Title>
        <Drawer.Content>
          <Text style={{color:'grey',textAlign:'center'}}>{detale}</Text>          
        </Drawer.Content>
        {/* <Drawer.Subtitle>{error&&error}</Drawer.Subtitle> */}
        <Spacer h={1}/>
        <Drawer.Content>
          <Text style={{fontSize:"12px",textAlign:'center'}}>
          {page&&<Link color href={page}>{status&&(lang?"Більше інформації":"More info")}</Link>}
          {/* • <span><Link color href="https://docs.google.com/forms/d/e/1FAIpQLSdAcgAFrT-NNeAqOBIlPdE5CnLexnTClvCEcLLavqDo50z29Q/viewform">Report a bug</Link></span> */}
          </Text>  
        </Drawer.Content>
      </Drawer>
      
    </div>
    
    
      

      
  </>
  )
}

export default App;

